import React from "react";
import L from "../Constants/Language";
import { connect } from "react-redux";
import { logoutUser } from "../Redux/actions/auth";
import { useNavigate } from "react-router-dom";
import LOGO from "../assets/Logo.png"

function Header({ logoutUser, userdata }) {

  const navigate = useNavigate();


  const onlogoutUser = async () => {
    await logoutUser()
    navigate("/")
  }

  return (
    <div>
      <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
        <div className="navbar-header">
          <ul className="nav navbar-nav flex-row">
            <li className="nav-item mr-auto"><span className="navbar-brand" >
              {/* <div className="brand-logo"></div> */}
              <img src={LOGO} width="35" alt="Ebads Arts Logo" />
              <h2 className="brand-text mb-0">EbadArts</h2>
            </span></li>
            <li className="nav-item nav-toggle"><a href="#abcd" className="nav-link modern-nav-toggle pr-0" data-toggle="collapse"><i className="feather icon-x d-block d-xl-none font-medium-4 primary toggle-icon"></i><i className="toggle-icon feather icon-disc font-medium-4 d-none d-xl-block collapse-toggle-icon primary" data-ticon="icon-disc"></i></a></li>
          </ul>
        </div>
        <div className="shadow-bottom"></div>
        <div className="main-menu-content">
          <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
            {/* <li className="nav-item"><a onClick={() => { navigate("/Dashboard") }} ><i className="feather icon-home"></i><span className="menu-title">{L.Dashboard}</span></a>
              </li> */}
            <li className="navigation-header"><span>{L.Menu}</span>
            </li>
            <li className="nav-item"><a onClick={(e) => [e.preventDefault(), navigate("/")]} href="Dashboard" >
              <i className="feather icon-home"></i><span className="menu-title">{L.Dashboard}</span></a>
            </li>
            <li className="nav-item"><a onClick={(e) => [e.preventDefault(), navigate("/Income")]} href="Income" >
              <i className="feather icon-grid"></i><span className="menu-title">Income</span></a>
            </li>
            <li className="nav-item"><a onClick={(e) => [e.preventDefault(), navigate("/Expense")]} href="Expense" >
              <i className="feather icon-zap"></i><span className="menu-title">Expense</span></a>
            </li>
            {/* <li className="nav-item"><a onClick={(e) => [e.preventDefault(), navigate("/Clients")]} href="Users" >
              <i className="feather icon-user"></i><span className="menu-title">Client</span></a>
            </li> */}
            {/* <li className="nav-item"><a onClick={(e) => [e.preventDefault(), navigate("/Users")]} href="Users" >
              <i className="feather icon-user"></i><span className="menu-title">Users</span></a>
            </li> */}
            <li className="nav-item"><a onClick={(e) => [e.preventDefault(), navigate("/ContactBook")]} href="Users" >
              <i className="feather icon-codepen"></i><span className="menu-title">Contact Book</span></a>
            </li>
            <li className="nav-item"><a className="dropdown-item" onClick={() => onlogoutUser()} ><i className="feather icon-power"></i> {L.Logout}</a>
            </li>




            {/* <li className="nav-item"><a onClick={() => { navigate("/Keys") }} href="Keys">
                <i className="feather icon-shield"></i><span className="menu-title">{L.Keys}</span></a>
              </li>
              <li className="nav-item"><a onClick={() => { navigate("/Guests") }} href="Guests">
                <i className="feather icon-check-square"></i><span className="menu-title">{L.Guests}</span></a>
              </li>
              <li className="nav-item"><a href="news" >
                <i className="feather icon-user"></i><span className="menu-title">{L.User}</span></a>
                <ul className="menu-content">
                  <a onClick={() => { navigate("/User_add") }} href="news"><i className="feather icon-circle"></i><span className="menu-item" >{L.Add_user}</span></a>
                  <a onClick={() => { navigate("/User_list") }} href="news"><i className="feather icon-circle"></i><span className="menu-item" >{L.List_of_user}</span></a>
                </ul>
              </li>
              <li className="nav-item"><a href="news" >
                <i className="feather icon-octagon"></i><span className="menu-title">{L.Gym}</span></a>
                <ul className="menu-content">
                  <a onClick={() => { navigate("/GymAdd") }} href="news"><i className="feather icon-circle"></i><span className="menu-item" >{L.Add_Gym}</span></a>
                  <a onClick={() => { navigate("/GymList") }} href="news"><i className="feather icon-circle"></i><span className="menu-item" >{L.List_of_Gym}</span></a>
                </ul>
              </li>
              <li className="nav-item"><a onClick={() => { navigate("/Category") }} href="news" >
                <i className="feather icon-grid"></i><span className="menu-title">{L.Category}</span></a>
              </li>
              <li className="nav-item"><a href="news">
                <i className="feather icon-user"></i><span className="menu-title">{L.Restaurant}</span></a>
                <ul className="menu-content">
                  <a href="#News" onClick={() => { navigate("/Restaurant_Add") }}><i className="feather icon-circle"></i><span className="menu-item" >{L.Add_Restaurant}</span></a>
                  <a href="#News" onClick={() => { navigate("/Restaurant_List") }}><i className="feather icon-circle"></i><span className="menu-item" >{L.List_of_Restaurant}</span></a>
                </ul>
              </li>
              <li className="nav-item"><a onClick={() => { navigate("/Order") }} >
                <i className="feather icon-shopping-cart"></i><span className="menu-title">{L.Orders}</span></a>
              </li> */}
          </ul>
        </div>
      </div>



    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    userdata: state.user

  }
}
const mapDispatchToProps = {
  logoutUser,
};


export default connect(mapStateToProps, mapDispatchToProps)(Header)