import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./Routes/Navigation";
import Global from "./Constants/Global";
import API from "./Constants/API";
import Login from "./Screens/Login";
import { updateAuth, updateToken, getUserData } from "./Redux/actions/auth";
import Header from "./Screens/Header";
import { useSelector, useDispatch } from "react-redux";

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    getStart();
  }, []);

  const getStart = async () => {
    var token = await Global.getData(API.AUTH_KEY);
    if (token) {
      dispatch(updateToken(token));
    }
  };

  const token = useSelector((state) => state.auth.token);

  return (
    <Router>
      {token ?
        <div>
          <Header />
          <Routes>
            {Navigation.map((route, idx) => (
              <Route path={route.path} element={route.element} key={idx} />
            ))}
          </Routes>
        </div>
        :
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      }
    </Router>
  );
}