import React, { Component } from "react";
import API from "../Constants/API";
import Global from "../Constants/Global";
import L from "../Constants/Language";
import '../login.css';
import { connect } from "react-redux";
import LOGO from "../assets/Logo.png"
import LoadingGIF from "../assets/loading.gif"

import { updateAuth, updateToken, getUserData } from "../Redux/actions/auth";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      email: "",
      password: "",
      // email: "",
      // password: "",
      secure: false,
      Error: ""
    };
  }

  Loginfunction = async () => {

    const container = document.getElementById('container');
    let { email, password } = this.state;

    if (email && password) {
      container.classList.add("right-panel-active");
      this.setState({ loading: true, Error: "" });

      // let token = "46|MnZ7jearqmCpIKX4MZXLx9Akjbd2mtkoiUhj8tYT"
      // let user = {
      //   "id": 19,
      //   "name": "Admin",
      //   "email": "admin@admin.com",
      //   "email_verified_at": null,
      //   "image": null,
      //   "phone": null,
      //   "type": "admin",
      //   "createdAt": "2022-12-01T21:56:06.000000Z",
      //   "updated_at": "2022-12-01T21:56:06.000000Z",
      //   "is_blocked": 0,
      // }

      // setTimeout(() => {
      //   this.setState({ loading: false, });
      //   container.classList.remove("right-panel-active");
      //   Global.saveData(API.AUTH_KEY, token)
      //   this.props.updateAuth({ token, user })
      // }, 1000)

      await Global.postRequest(API.LOGIN, { email, password })
        .then(res => {
          if (res?.success) {
            this.setState({ loading: false });
            container.classList.remove("right-panel-active");
            Global.saveData(API.AUTH_KEY, res.data.token)

            // // let path = '/Dashborad';
            // // this.props.history.push(path)
            this.props.updateAuth(res.data)

          } else {
            this.setState({ secure: true, Error: L.InvalidCredential })
            container.classList.remove("right-panel-active");
          }
        })

    }
    else {
      this.setState({ Error: L.FillAllfields })
    }
  }

  screenChange = () => {
    const container = document.getElementById('container');
    container.classList.remove("right-panel-active");
    this.setState({ secure: false, Error: "", email: "", password: "" })
  }

  render() {
    return (
      <div className="loginpage">

        <div className="container" id="container">
          <div className="form-container sign-up-container">
            <div className="forms">
              <center>
                <img width="50" className="rounded-circl" src={LoadingGIF} alt="" />
                <h2>{L.Waitmoment}</h2>
              </center>
            </div>
          </div>
          <div className="form-container sign-in-container">
            <div className="forms">
              <h1>{L.Signin}</h1>
              <span>{L.anduseyouraccount}</span>

              <input className="inputs" onChange={(val) => { this.setState({ email: val.target.value }) }} value={this.state.email} placeholder={L.EnterEmail} type="email" />
              <input className="inputs" onChange={(val) => { this.setState({ password: val.target.value }) }} value={this.state.password} placeholder={L.EnterPassword} type="password" />
              <button onClick={() => { this.Loginfunction() }}>{L.Login}</button>
              <h5 >{this.state.Error}</h5>

            </div>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <img src={LOGO} width="200" alt="Ebad arts" />
                {this.state.secure ?
                  <div>
                    <h1>{L.Warning}</h1>
                    <p>{L.LoginText}</p>
                    <button className="ghost" onClick={this.screenChange.bind(this)}>{L.TryAgain}</button>
                  </div>
                  :
                  null}

              </div>
              <div className="overlay-panel overlay-right">
                <img src={LOGO} width="200" alt="Ebad arts" />
                <h1>{L.HelloFriend}</h1>
                <p>{L.enterDetails}</p>
              </div>
            </div>
          </div>
        </div>


      </div>

    );
  }

}

const mapDispatchToProps = {
  updateAuth,
  getUserData,
  updateToken,
};

export default connect(null, mapDispatchToProps)(Login)