import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Components/Loader";
import API from "../Constants/API";
import Global from "../Constants/Global";
import { addIncome, editIncome } from "../Redux/actions/income";
import { getAllContactBook } from "../Redux/actions/contactBook";


export default function AddIncome({ data = null, edit = false, onEditChange }) {
    const dispatch = useDispatch();

    const contactBookLoading = useSelector((state) => state.contactBook.allContactBookLoading);
    const contactBooks = useSelector((state) => state.contactBook.allContactBookData || []);

    const [loading, setLoading] = useState(false)
    const [ContactBookName, setContactBookName] = useState("");
    const [contactBookId, setContactBookId] = useState("");
    const [Received, setReceived] = useState("")
    const [Amount, setAmount] = useState("")
    const [Detail, setDetail] = useState("")

    useEffect(() => {
        if (edit && data) {
            setContactBookName(data.clientName)
            setReceived(data.received)
            setAmount(data.amount)
            setDetail(data.des)
        } else {
            setContactBookName("")
            setReceived("")
            setAmount("")
            setDetail("")
        }
    }, [data])

    const handleCallcontactBook = () => {
        dispatch(getAllContactBook(""));
    }

    const OnEditSubmit = async (ev) => {
        ev.preventDefault();
        setLoading(true)
        const closeForm = document.getElementById('incomeModalButton');

        let DataToSend = {
            id: data.id,
            amount: Amount,
            received: Received,
            clientName: ContactBookName,
            des: Detail,
            contactBook_id: contactBookId,
        }

        if (Number(Amount) === Number(Received)) {
            DataToSend.paymentStatus = "paid"
        } else {
            DataToSend.paymentStatus = "unpaid"
        }

        await Global.postRequest(API.UPDATE_INCOME, DataToSend)
            .then(res => {
                if (res?.success) {
                    dispatch(editIncome({ ...data, ...DataToSend }))
                    setContactBookName("")
                    setReceived("")
                    setAmount("")
                    setDetail("")
                    closeForm.click()
                    onEditChange()
                }
                setLoading(false)
            })
    }

    const OnSubmit = async (ev) => {
        ev.preventDefault();

        setLoading(true)
        const closeForm = document.getElementById('incomeModalButton');

        let DataToSend = {
            amount: Amount,
            received: Received,
            clientName: ContactBookName,
            des: Detail,
            contactBook_id: contactBookId,
        }

        await Global.postRequest(API.ADD_INCOME, DataToSend)
            .then(res => {
                if (res?.success) {
                    dispatch(addIncome(res.data))
                    setContactBookName("")
                    setReceived("")
                    setAmount("")
                    setDetail("")
                    closeForm.click()
                }
                setLoading(false)
            })
    }

    let btnDisabled = false
    if (Number(Received) > Number(Amount)) {
        btnDisabled = true
    }
    let Balance = Amount - Received

    return (
        <div
            className="modal fade"
            id="incomeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-dialog-centered modal-dialog-scrollable"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5
                            className="modal-title primary"
                            id="exampleModalCenterTitle"
                        >
                            Add New Income
                        </h5>
                        <button
                            id="incomeModalButton"
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form
                        className="form form-horizontal"
                        id="sendNotification"
                        onSubmit={(e) =>
                            edit && data
                                ? OnEditSubmit(e)
                                : OnSubmit(e)
                        }
                    >
                        <div className="modal-body">
                            {/* ////////////////////Body */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label"><label for="Title">Client Name *</label></div>

                                        <div className="col-sm-9">
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    id="client"
                                                    className="form-control form-control-lg"
                                                    name="client"
                                                    value={ContactBookName}
                                                    onChange={(v) => [setContactBookName(v.target.value), setContactBookId(null)]}
                                                    required
                                                />
                                                <div className="input-group-append">
                                                    <button
                                                        onClick={() => handleCallcontactBook()}
                                                        type="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        className="dropdown-toggle btn btn-primary"
                                                    >
                                                        List
                                                    </button>
                                                    <div
                                                        tabindex="-1"
                                                        role="menu"
                                                        aria-hidden="true"
                                                        className="dropdown-menu"
                                                    >
                                                        {contactBookLoading ? <Loader /> : contactBooks.length === 0
                                                            ? null
                                                            : contactBooks.map((e) => (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => [setContactBookName(e.name), setContactBookId(e.id)]}
                                                                    className="dropdown-item"
                                                                >
                                                                    {e.name}
                                                                </button>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label"><label for="Title">Received *</label></div>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                id="received"
                                                className="form-control form-control-lg"
                                                name="received"
                                                value={Received}
                                                onChange={(v) => setReceived(v.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label"><label for="Title">Amount *</label></div>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                id="amount"
                                                className="form-control form-control-lg"
                                                name="amount"
                                                value={Amount}
                                                onChange={(v) => setAmount(v.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label"><label for="Title">Balance</label></div>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                id="balance"
                                                className="form-control form-control-lg"
                                                name="balance"
                                                value={Balance}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label"><label for="Title">Detail</label></div>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                id="detail"
                                                className="form-control form-control-lg"
                                                name="detail"
                                                value={Detail}
                                                onChange={(v) => setDetail(v.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <Alertbar status={alertStatus} message={alertMessage} /> */}
                            {/* ////////////////////Body */}
                        </div>
                        <div className="modal-footer">
                            {loading ?
                                <div
                                    className="spinner-border text-primary"
                                    role="status"
                                />
                                :
                                <div>
                                    <button
                                        disabled={btnDisabled}
                                        type="submit"
                                        className="btn btn-primary mr-1"
                                    >
                                        Submit
                                    </button>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div >
    );
}
