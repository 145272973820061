import Dashboard from "../Screens/Dashboard";
import Logout from "../Screens/Logout";
import Expense from "../Screens/Expense";
import Users from "../Screens/Users";
// import Clients from "../Screens/Clients";
import ContactBook from "../Screens/ContactBook";
import Income from "../Screens/Income";

const Navigation = [
  { path: "/", element: <Dashboard /> },
  { path: "/Logout", element: <Logout /> },
  { path: "/ContactBook", element: <ContactBook /> },
  // { path: "/Clients", element: <Clients /> },
  { path: "/Users", element: <Users /> },
  { path: "/Expense", element: <Expense /> },
  { path: "/Income", element: <Income /> },
];

export default Navigation;
