import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import AddExpense from "../Components/AddExpense";
import Loader from "../Components/Loader";
import Pagination from "../Components/Pagination";
import { getExpense, deleteExpense } from "../Redux/actions/expense";
import API from "../Constants/API";
import Global from "../Constants/Global";


export default function Expense({ }) {
  const dispatch = useDispatch();

  const expenseData = useSelector((state) => state.expense.expenseData);
  const totalPages = useSelector((state) => state.expense.totalPages);
  const isLoading = useSelector((state) => state.expense.isLoading);

  const [filterPattern, setfilterPattern] = useState("");
  const [singleExpense, setsingleExpense] = useState(null);
  const [edit, setEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(10);

  useEffect(() => {
    dispatch(getExpense(currentPage, currentLimit))
  }, [currentPage, currentLimit]);

  const newdata = expenseData.filter((item) =>
    item.clientName.toUpperCase().includes(filterPattern.toUpperCase())
  );

  const onDelete = async (val) => {
    var answer = window.confirm("Delete this record?");
    if (answer)
      await Global.deleteRequest(API.DELETE_EXPENSE + val.id)
        .then(res => {
          if (res?.success) {
            dispatch(deleteExpense(val.id))
          }
        })
  }


  return (
    <div className="abdul">
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="content-wrapper mt-1">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  <h2 className="content-header-title float-left mb-0">
                    All Expenses
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="content-body">
            <div className="row">
              <div className="col-4">
                <div class="search-bar">
                  <fieldset class="form-group position-relative has-icon-left">
                    <input
                      type="text"
                      class="form-control"
                      onChange={(val) => setfilterPattern(val.target.value)}
                      value={filterPattern}
                      placeholder="Search expense by client name"
                    />
                    <div class="form-control-position">
                      <i class="feather icon-search px-1"></i>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="col-6">
                <div className="search-bar">
                  <button
                    type="button"
                    className="btn bg-gradient-primary mr-1 mb-1"
                    data-toggle="modal"
                    data-target="#expenseModal"
                    onClick={() => [setsingleExpense(null), setEdit(false)]}
                  >
                    Add Expense
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="table-responsive">
                        {isLoading ? <Loader /> :
                          newdata.length === 0 ?
                            <div style={{ color: "gray", fontSize: 14 }}>
                              No record found
                            </div>
                            :
                            <table className="table table-hover-animation mb-0 table-striped">
                              <thead>
                                <tr>
                                  <th className="text-center">ID</th>
                                  <th>Client Name</th>
                                  <th className="text-center">Amount</th>
                                  <th className="text-center">Received</th>
                                  <th className="text-center">Status</th>
                                  <th className="text-center">Type</th>
                                  <th className="text-center">Date</th>
                                  <th className="text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {newdata.map((val, index) => {
                                  let date = moment(val?.createdAt).format("DD MMM YY, h:mm a")
                                  return (
                                    <tr key={index}>
                                      <td
                                        className="text-center"
                                        onClick={() => setsingleExpense(val)}
                                        data-toggle="modal"
                                        data-target="#expenseDetailModal"
                                      >{val.id}</td>
                                      <td>{val.clientName}</td>
                                      <td className="text-center">{val.amount}</td>
                                      <td className="text-center">{val.received}</td>
                                      <td className="text-center">{val?.paymentStatus}</td>
                                      <td className="text-center">{val.type}</td>
                                      <td className="text-center">{date}</td>
                                      {/* <td className="text-center">{date}</td> */}
                                      <td className="text-center">
                                        <span
                                          className="mr-1"
                                          data-toggle="modal"
                                          data-target="#expenseModal"
                                          onClick={() => [setsingleExpense(val), setEdit(true)]}
                                        >
                                          <i className="feather text-warning icon-edit h5"></i>{" "}
                                        </span>
                                        <span
                                          // data-toggle="modal"
                                          // data-target="#incomeModal"
                                          onClick={() => onDelete(val)}
                                        >
                                          <i className="feather text-danger icon-trash h5"></i>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                        }
                      </div>

                      {newdata.length === 0 ? null :
                        <Pagination
                          page={totalPages}
                          limit={currentLimit}
                          total={35}
                          onPageChange={(page) => setCurrentPage(page)}
                          onLimitChange={(page) => setCurrentLimit(page)}
                        />
                      }

                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Modal UPDATE User Detail--> */}
              <div
                className="modal fade"
                id="expenseDetailModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-dialog-centered modal-dialog-scrollable"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title primary"
                        id="exampleModalCenterTitle"
                      >
                        Expense Details # {singleExpense?.id}
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {/* ////////////////////Body */}
                      <div className="row">
                        <div className="col-md-7">
                          <p>
                            clientName: <strong>{singleExpense?.clientName}</strong>
                          </p>
                          <p>
                            Amount: <strong>{singleExpense?.amount}</strong>
                          </p>
                          <p>
                            Received: <strong>{singleExpense?.received}</strong>
                          </p>
                          <p>
                            Type: <strong>{singleExpense?.type}</strong>
                          </p>
                          <p>
                            Payment Status: <strong>{singleExpense?.paymentStatus}</strong>
                          </p>
                          <p>
                            Detail: {singleExpense?.des || " - "}
                          </p>
                          <p>
                            Creator Name: <strong>{singleExpense?.user?.fullName}<br /></strong>
                            Created Email: <strong>{singleExpense?.user?.email}</strong>
                          </p>
                          <p>
                            Create Date: <strong>{moment(singleExpense?.createdAt).format("DD MMM YYYY, h:mm a")}</strong><br />
                            Update Date: <strong>{moment(singleExpense?.updateAt).format("DD MMM YYYY, h:mm a")}</strong><br />
                          </p>
                        </div>
                      </div>
                      {/* ////////////////////Body */}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Modal UPDATE --> */}

              {/* <!-- Modal INCOME START --> */}
              <AddExpense
                edit={edit}
                data={singleExpense}
                onEditChange={() => setEdit(false)} />
              {/* <!-- Modal UPDATE INCOME END--> */}

            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
