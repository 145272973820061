const initialState = {
    isLoading: false,
    listLoading: true,

    id: '',
    name: '',
    phone: '',
    miles: '',
    date_of_birth: '',
    image: '',
    gender: '',
    email: '',
    email_verified_at: '',

    users_list: [],
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PERSONAL':
            return {
                ...state,
                id: action.payload._id,
                name: action.payload.name,
                email: action.payload.email,
            };
        case 'UPDATE_PERSONAL':
            return {
                ...state,
                name: action.payload.name,
                phone: action.payload.phone,
            };
        case 'DELETE_USER':
            const newGuest = state.users_list.filter(item => {
                return item.id !== action.payload
            })
            return {
                ...state,
                users_list: newGuest,
            };
        case 'GET_USERS':
            return {
                ...state,
                users_list: action.payload,
                listLoading: false
            };
        case 'BlOCK_USER':
            let temp = [...state.users_list]
            temp[action.payload.index].is_blocked = action.payload.is_blocked

            return {
                ...state,
                users_list: temp,
            };

        default:
            return state
    }
};
export default user