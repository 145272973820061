import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import API from "../Constants/API";
import Global from "../Constants/Global";
import { addContactBook, editContactBook } from "../Redux/actions/contactBook";


export default function AddContactBook({ data = null, edit = false, onEditChange }) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (edit && data) {
            setName(data.name)
            setContact(data.contact)
            setEmail(data.email)
            setAddress(data.address)
            setType(data.type)
        } else {
            setName("")
            setContact("")
            setEmail("")
            setAddress("")
            setType("")
        }
    }, [data])

    const [loading, setLoading] = useState(false)
    const [ClientName, setName] = useState("")
    const [contact, setContact] = useState("")
    const [Email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [type, setType] = useState("")


    const OnEditSubmit = async (ev) => {
        ev.preventDefault();
        setLoading(true)
        const closeForm = document.getElementById('contactBookModalButton');

        let DataToSend = {
            id: data.id,
            "email": Email,
            "contact": contact,
            "name": ClientName,
            "address": address,
            "type": type
        }

        if (data.id)
            await Global.postRequest(API.UPDATE_CONTACTBOOK, DataToSend)
                .then(res => {
                    if (res?.success) {
                        dispatch(editContactBook({ ...data, ...DataToSend }))
                        setName("")
                        setContact("")
                        setEmail("")
                        setAddress("")
                        setType("")
                        closeForm.click()
                        onEditChange()
                    }
                    setLoading(false)
                })
    }

    const OnSubmit = async (ev) => {
        ev.preventDefault();

        setLoading(true)
        const closeForm = document.getElementById('contactBookModalButton');

        let DataToSend = {
            "email": Email,
            "contact": contact,
            "name": ClientName,
            "address": address,
            "type": type,
        }

        await Global.postRequest(API.ADD_CONTACTBOOK, DataToSend)
            .then(res => {
                if (res?.success) {
                    dispatch(addContactBook(res.data))
                    setName("")
                    setContact("")
                    setEmail("")
                    setAddress("")
                    setType("")
                    closeForm.click()
                }
                setLoading(false)
            })
    }

    let btnDisabled = false
    // if (contact > Email) {
    //     btnDisabled = true
    // }

    return (
        <div
            className="modal fade"
            id="contactBookModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-dialog-centered modal-dialog-scrollable"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5
                            className="modal-title primary"
                            id="exampleModalCenterTitle"
                        >
                            Add New Contact Book
                        </h5>
                        <button
                            id="contactBookModalButton"
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form
                        className="form form-horizontal"
                        id="sendNotification"
                        onSubmit={(e) =>
                            edit && data
                                ? OnEditSubmit(e)
                                : OnSubmit(e)
                        }
                    >
                        <div className="modal-body">
                            {/* ////////////////////Body */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label"><label for="Title">Name *</label></div>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                id="client"
                                                className="form-control form-control-lg"
                                                name="client"
                                                value={ClientName}
                                                onChange={(v) => setName(v.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label"><label for="Title">Contact *</label></div>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                id="contact"
                                                className="form-control form-control-lg"
                                                name="contact"
                                                value={contact}
                                                onChange={(v) => setContact(v.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label"><label for="Title">Email</label></div>
                                        <div className="col-sm-9">
                                            <input
                                                id="email"
                                                className="form-control form-control-lg"
                                                name="email"
                                                value={Email}
                                                onChange={(v) => setEmail(v.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <div className="input-group"
                                                    onClick={() => setType("client")}
                                                >
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <input type="radio" checked={type === "client" ? true : false} />
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder="Client"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <div className="input-group"
                                                    onClick={() => setType("shop")}
                                                >
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <input type="radio" checked={type === "shop" ? true : false} />
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder="Shop"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <div className="input-group"
                                                    onClick={() => setType("supplier")}
                                                >
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <input type="radio" checked={type === "supplier" ? true : false} />
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder="Supplier"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <div className="input-group"
                                                    onClick={() => setType("other")}
                                                >
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <input type="radio" checked={type === "other" ? true : false} />
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Other"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label"><label for="Title">Address</label></div>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                id="detail"
                                                className="form-control form-control-lg"
                                                name="detail"
                                                value={address}
                                                onChange={(v) => setAddress(v.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <Alertbar status={alertStatus} message={alertMessage} /> */}
                            {/* ////////////////////Body */}
                        </div>
                        <div className="modal-footer">
                            {loading ?
                                <div
                                    className="spinner-border text-primary"
                                    role="status"
                                />
                                :
                                <div>
                                    <button
                                        disabled={btnDisabled ? true : false}
                                        type="submit"
                                        className="btn btn-primary mr-1"
                                    >
                                        Submit
                                    </button>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div >
    );
}
