class API {

  static BASE_URL = 'http://localhost:3000/'
  // static BASE_URL = 'https://ebadarts.onrender.com/'

  static IMAGE_URL_ADMIN = ''
  static IMAGE_URL = 'https://ebadarts.onrender.com/'
  static AUTH_KEY = 'SKuihC&IUHSBAH(A'

  static TOKEN = ""

  static LOGIN = 'auth/login'

  static GET_DASHBOARD = 'dashboard'

  static GET_INCOME = 'income'
  static GET_INCOME_ID = 'income/'
  static ADD_INCOME = 'income'
  static UPDATE_INCOME = 'income/update'
  static DELETE_INCOME = 'income/'

  static GET_EXPENSE = 'expense'
  static GET_EXPENSE_ID = 'expense/'
  static ADD_EXPENSE = 'expense'
  static UPDATE_EXPENSE = 'expense/update'
  static DELETE_EXPENSE = 'expense/'

  static GET_USER = 'users'
  static GET_USER_ID = 'users/'
  // static ADD_USER = 'users'
  static UPDATE_USER = 'users/update'
  static DELETE_USER = 'users/'

  static GET_CONTACTBOOK = 'contactBook'
  static GET_ALL_CONTACTBOOK = 'contactBook/all'
  static ADD_CONTACTBOOK = 'contactBook'
  static UPDATE_CONTACTBOOK = 'contactBook/update'
  static DELETE_CONTACTBOOK = 'contactBook/'

  static GET_CLIENT = 'client'
  static GET_CLIENT = 'client/'
  static ADD_CLIENT = 'client'
  static UPDATE_CLIENT = 'client/update'
  static DELETE_CLIENT = 'client/'


}


export default API