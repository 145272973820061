import React from "react";

export default function Loader() {

    return (
        <div
            className="m-1 spinner-border text-primary"
            role="status"
        />
    );
}
