import API from "../../Constants/API";
import G from "../../Constants/Global";

export const getIncome = (page, limit) => {
  return (dispatch) => {
    dispatch({ type: "INCOME_LOADING" });
    G.getRequest(API.GET_INCOME, { page, limit }).then((res) => {
      if (res.success) {
        dispatch({ type: "GET_INCOME", payload: res.data });
      } else {
        dispatch({ type: "GET_INCOME", payload: [] });
      }
    });
  };
};

export const addIncome = (payload) => {
  return {
    type: "ADD_INCOME",
    payload,
  };
};

export const editIncome = (payload) => {
  return {
    type: "EDIT_INCOME",
    payload,
  };
};
export const deleteIncome = (payload) => {
  return {
    type: "DELETE_INCOME",
    payload,
  };
};
