import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import L from "../Constants/Language";
// import API from "../Constants/API";
import { getUsers } from "../Redux/actions/user";
// import Global from "../Constants/Global"



export default function Users({ }) {
  const dispatch = useDispatch();

  const users_list = useSelector((state) => state.user.users_list);
  const listLoading = useSelector((state) => state.user.listLoading);

  const [filterPattern, setfilterPattern] = useState("");
  // const [singleUser, setsingleUser] = useState(null);

  useEffect(() => {
    // dispatch(getUsers())
  }, []);

  const onDelete = async (val) => {
    var answer = window.confirm("Delete this record?");
    // if (answer)
    //   await Global.deleteRequest(API.DELETE_CONTACTBOOK + val.id)
    //     .then(res => {
    //       if (res?.success) {
    //         dispatch(deleteUser(val.id))
    //       }
    //     })
  }

  const blockUserAPI = (val, index) => {
    // var sendData = {
    //   userid: val.id,
    //   status: val.is_blocked === "0" ? "1" : "0",
    //   is_blocked: val.is_blocked === "0" ? "1" : "0",
    //   index: index,
    // };

    // Global.postRequest(API.UPDATE_USERS + val.id, sendData)
    //   .then((res) => {
    //     console.log(res.data, 'xxx')
    //     if (res.data?.success) {
    //       blockUser(sendData)
    //     }
    //   })
  }

  const newdata = users_list.filter((item) =>
    item.fullName.toUpperCase().includes(filterPattern.toUpperCase())
  );

  return (
    <div className="abdul">
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="content-wrapper mt-1">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  <h2 className="content-header-title float-left mb-0">
                    Users List
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="content-body">

            <div className="row">
              <div className="col-4">
                <div className="search-bar">
                  <fieldset className="form-group position-relative has-icon-left">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(val) => setfilterPattern(val.target.value)}
                      value={filterPattern}
                      placeholder={L.Searchuserbyname}
                    />
                    <div className="form-control-position">
                      <i className="feather icon-search px-1"></i>
                    </div>
                  </fieldset>
                </div>
              </div>
              {/* <div className="col-1">
                                <div className="search-bar">
                                    <button type="button" className="btn bg-gradient-primary mr-1 mb-1">Search</button>
                                </div>
                            </div> */}
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      {listLoading ? (
                        <div
                          className="spinner-border text-primary text-center"
                          role="status"
                        >
                          <span className="sr-only">{L.Loading}...</span>
                        </div>
                      ) :
                        newdata.length === 0 ?
                          <div style={{ color: "gray", fontSize: 14 }}>
                            No record found
                          </div>
                          :
                          <div className="table-responsive ">
                            <table className="table table-striped table-hover-animation mb-0">
                              <thead>
                                <tr>
                                  <th>
                                    {L.Name} | {L.Email}
                                  </th>
                                  <th className="text-center">Income</th>
                                  <th>Join date</th>
                                  <th className="text-center">{L.Status}</th>
                                  <th className="text-center">{L.Block_User}</th>
                                  {/* <th className="text-center">{L.Action}</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {newdata.map((val, index) => {
                                  var date = moment(val.createdAt).format(
                                    "DD/MM/YY, h:mm a"
                                  );
                                  return (
                                    <tr key={index}>
                                      {/* <td className="text-center">
                                        <ul className="list-unstyled users-list ">
                                          <li
                                            data-toggle="tooltip"
                                            data-popup="tooltip-custom"
                                            data-placement="bottom"
                                            data-original-title="Trina Lynes"
                                            className="avatar pull-up"
                                          >
                                            {val.image ? (
                                              <img
                                                className="media-object rounded-circle"
                                                src={API.IMAGE_URL + val.image}
                                                alt={val.name}
                                                height="45"
                                                width="45"
                                              />
                                            ) : (
                                              <img
                                                className="media-object rounded-circle"
                                                src="../../app-assets/images/noimage.jpg"
                                                alt={val.name}
                                                height="45"
                                                width="45"
                                              />
                                            )}
                                          </li>
                                        </ul>
                                      </td> */}
                                      <td>
                                        <h5>{val.fullName}</h5>
                                        <span>{val.email}</span>
                                      </td>
                                      {/* <td className="text-center">
                                        {val.notifications?.length}
                                      </td> */}
                                      <td className="text-center">
                                        {val?.income?.length}
                                      </td>
                                      <td>{date}</td>

                                      {val.email_verified_at ? (
                                        <td className="text-center">
                                          <i className="fa fa-circle font-small-3 text-success mr-50"></i>
                                          {L.Verified}
                                        </td>
                                      ) : (
                                        <td className="text-center">
                                          <i className="fa fa-circle font-small-3 text-warning mr-50"></i>
                                          {L.Pending}
                                        </td>
                                      )}

                                      <td className="text-center">
                                        <div className="custom-control custom-switch custom-switch-success mr-2 mb-1">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={val.id}
                                            // onChange={() => blockUserAPI(val, index)}
                                            checked={val.is_blocked === "0" ? false : true}
                                          />
                                          <label
                                            className="custom-control-label"
                                            for={val.id}
                                          ></label>
                                        </div>
                                      </td>

                                      {/* <td className="text-center">
                                        <i className="feather text-warning icon-edit h4"></i>{" "}
                                        <span onClick={() => { deleteUser(val.id) }}>
                                          <i className="feather text-danger icon-trash-2 h4"></i>{" "}
                                        </span>
                                      </td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
