const initialState = {
    id: null,
    token: null,
    userdata: null,
    dashboardData: [],
    dashboardLoading: true
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_DASHBOARD':
            return {
                ...state,
                dashboardData: action.payload,
                dashboardLoading: false,
            };
        case 'UPDATE_AUTH':
            return {
                id: action.payload.user?.id,
                token: action.payload.token,
                userdata: action.payload.user,
            };
        case 'UPDATE_USER':
            return {
                ...state,
                id: action.payload?.id,
                userdata: action.payload,
            };

        case 'UPDATE_TOKEN':
            return {
                ...state,
                token: action.payload,
            };
        case 'DESTORY_AUTH':
            return {
                id: null,
                token: null,
                userdata: null,
            };

        default:
            return state
    }
};

export default auth