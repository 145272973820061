import React, { useEffect, useState } from "react";

export default function Pagination({ page = 1, limit, total, onPageChange, onLimitChange }) {
    const [list, setList] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);

    useEffect(() => {
        countingMapping();
    }, [currentPage, page]);


    const setPage = (i) => {
        setcurrentPage(i)
        onPageChange(i)
    }

    const countingMapping = () => {
        let temp = [];

        for (let i = 1; i < page + 1; i++) {
            temp.push(
                <li
                    key={i}
                    className={`page-item ${currentPage === i ? 'active' : ''}`}
                    onClick={() => setPage(i)}
                >
                    <a href="javascript:void(0);" className="page-link">
                        {i}
                    </a>
                </li>
            );
        }
        setList(temp);
    };

    return (
        <div className="row align-items-center">
            <div className="col-md-11">
                <ul className="pagination mt-2">
                    <li className="page-item"
                        onClick={() => currentPage === 1 ? null : setPage(currentPage - 1)}
                    >
                        <a href="javascript:void(0);" className="page-link" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span className="sr-only">Previous</span>
                        </a>
                    </li>

                    {list.map((e) => e)}

                    <li className="page-item"
                        onClick={() => currentPage === page ? null : setPage(currentPage + 1)}
                    >
                        <a href="javascript:void(0);" className="page-link" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span className="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="col-md-1 text-center">
                <select
                    className="form-control"
                    onChange={(e) => onLimitChange(Number(e.target.value))}
                >
                    {/* <option value={3}>3</option> */}
                    <option value={10}>10</option>
                    {page > 20 ?
                        <option value={20}>20</option>
                        : null}
                    {page > 50 ?
                        <option value={50}>50</option>
                        : null}
                    {page > 1000 ?
                        <option value={1000}>All</option>
                        : null}
                </select>
            </div>


        </div>
    );
}
