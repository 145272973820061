import React, { Component } from 'react';
import { connect } from 'react-redux'
import { logoutUser } from "../Redux/actions/auth";
import L from "../Constants/Language";

class Logout extends Component {

    componentDidMount() {
        this.props.logoutUser()
        window.location.reload()
    }

    render() {
        return (
            <div className="row">
                {L.YouSuccessfullLogout}
            </div>
        );
    }
}

const mapDispatchToProps = {
    logoutUser,
};


export default connect(null, mapDispatchToProps)(Logout)
