import API from "../../Constants/API";
import G from "../../Constants/Global";

export const getContactBook = (page, limit) => {
    return (dispatch) => {
        dispatch({ type: "CONTACTBOOK_LOADING" });
        G.getRequest(API.GET_CONTACTBOOK, { page, limit }).then((res) => {
            if (res.success) {
                dispatch({ type: "GET_CONTACTBOOK", payload: res.data });
            } else {
                dispatch({ type: "GET_CONTACTBOOK", payload: [] });
            }
        });
    };
};

export const getAllContactBook = (text) => {
    return (dispatch) => {
        dispatch({ type: "GET_ALL_CONTACTBOOK_LOADING" });
        G.postRequest(API.GET_ALL_CONTACTBOOK, { text }).then((res) => {
            if (res.success) {
                dispatch({ type: "GET_ALL_CONTACTBOOK", payload: res.data });
            } else {
                dispatch({ type: "GET_ALL_CONTACTBOOK", payload: [] });
            }
        });
    };
};

export const listContactBook = (payload) => {
    return {
        type: "GET_CONTACTBOOK",
        payload,
    };
};

export const addContactBook = (payload) => {
    return {
        type: "ADD_CONTACTBOOK",
        payload,
    };
};

export const editContactBook = (payload) => {
    return {
        type: "EDIT_CONTACTBOOK",
        payload,
    };
};
export const deleteContactBook = (payload) => {
    return {
        type: "DELETE_CONTACTBOOK",
        payload,
    };
};
