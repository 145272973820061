const initialState = {
    isLoading: true,
    incomeData: [],
    totalPages: 0,
}

const income = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_INCOME':
            return {
                ...state,
                isLoading: false,
                incomeData: action.payload.data,
                totalPages: action.payload.totalPages,
            };
        case 'INCOME_LOADING':
            return {
                ...state,
                isLoading: true,
            };
        case 'ADD_INCOME':
            return {
                ...state,
                isLoading: false,
                incomeData: [action.payload, ...state.incomeData],
            };
        case 'EDIT_INCOME':
            var temp = []
            state.incomeData.forEach(item => {
                if (item.id === action.payload.id) {
                    temp.push(action.payload)
                } else {
                    temp.push(item)
                }
            })
            return {
                ...state,
                incomeData: temp,
            };
        case 'DELETE_INCOME':
            var temp = state.incomeData.filter(item => item.id !== action.payload)
            return {
                ...state,
                incomeData: temp,
            };

        default:
            return state
    }
};
export default income