import { configureStore } from '@reduxjs/toolkit'

import auth from './auth_reducer';
import user from './user_reducer';
import income from './income_reducer';
import expense from './expense_reducer';
import contactBook from './contactBook_reducer';

export default configureStore({
    reducer: {
        auth,
        user,
        income,
        expense,
        contactBook,
    }
});
