const initialState = {
    isLoading: true,
    contactBookData: [],
    totalPages: 0,
    allContactBookLoading: true,
    allContactBookData: [],
}

const contactBook = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CONTACTBOOK':
            return {
                ...state,
                isLoading: false,
                contactBookData: action.payload.data,
                totalPages: action.payload.totalPages,
            };
        case 'CONTACTBOOK_LOADING':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_ALL_CONTACTBOOK':
            return {
                ...state,
                allContactBookLoading: false,
                allContactBookData: action.payload,
            };
        case 'GET_ALL_CONTACTBOOK_LOADING':
            return {
                ...state,
                allContactBookLoading: true,
            };
        case 'ADD_CONTACTBOOK':
            return {
                ...state,
                isLoading: false,
                contactBookData: [action.payload, ...state.contactBookData],
            };
        case 'EDIT_CONTACTBOOK':
            var temp = []
            state.contactBookData.forEach(item => {
                if (item.id === action.payload.id) {
                    temp.push(action.payload)
                } else {
                    temp.push(item)
                }
            })
            return {
                ...state,
                contactBookData: temp,
            };
        case 'DELETE_CONTACTBOOK':
            var temp = state.contactBookData.filter(item => item.id !== action.payload)
            return {
                ...state,
                contactBookData: temp,
            };

        default:
            return state
    }
};
export default contactBook