import axios from "axios"
import API from "./API"
import queryString from "query-string";

class Global {

    static USER_TOKEN_DETAIL = { auth: '', id: '' }

    static getRequest(url, params) {

        const newUrl = queryString.stringifyUrl({
            url: API.BASE_URL + url,
            query: params,
        });

        return new Promise(async resolve => {
            const token = await this.getData(API.AUTH_KEY)
            axios.get(newUrl, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                }
            })
                .then((res) => {
                    // console.log(res, 'res')
                    resolve(res.data)
                })
                .catch((err) => {
                    // console.log('err', err.response)
                    resolve(err.response)
                })
        })
    }

    static deleteRequest(url) {
        return new Promise(async resolve => {
            const token = await this.getData(API.AUTH_KEY)
            axios.delete(API.BASE_URL + url, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                }
            })
                .then((res) => {
                    // console.log(res, 'res')
                    resolve(res.data)
                })
                .catch((err) => {
                    // console.log('err', err.response)
                    resolve(err.response)
                })
        })
    }



    static postRequest(url, data) {
        return new Promise(async resolve => {
            const token = await this.getData(API.AUTH_KEY)
            axios.post(API.BASE_URL + url, data, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                    "processData": false,
                    'X-Requested-With': 'XMLHttpRequest',
                }
            })
                .then((res) => {
                    // console.log(res, 'res')
                    resolve(res.data)
                })
                .catch((err) => {
                    console.log('err', err)
                    console.log('err', err?.response)
                    resolve(err?.response)
                })
        })
    }

    static saveData(key, data) {
        const jsonValue = JSON.stringify(data)
        localStorage.setItem(key, jsonValue)
    }

    static async getData(key) {
        return await new Promise(async (resolve) => {
            const jsonValue = await localStorage.getItem(key)
            var res = jsonValue != null ? JSON.parse(jsonValue) : null;
            resolve(res)
        })
    }
    static removeData(key) {
        localStorage.removeItem(key);
        console.log("DELTED")
    }


}
export default Global