import API from "../../Constants/API";
import G from "../../Constants/Global";

export const getExpense = (page, limit) => {
  return (dispatch) => {
    dispatch({ type: "EXPENSE_LOADING" });
    G.getRequest(API.GET_EXPENSE, { page, limit }).then((res) => {
      if (res.success) {
        dispatch({ type: "GET_EXPENSE", payload: res.data });
      } else {
        dispatch({ type: "GET_EXPENSE", payload: [] });
      }
    });
  };
};

export const addExpense = (payload) => {
  return {
    type: "ADD_EXPENSE",
    payload,
  };
};

export const editExpense = (payload) => {
  return {
    type: "EDIT_EXPENSE",
    payload,
  };
};

export const deleteExpense = (payload) => {
  return {
    type: "DELETE_EXPENSE",
    payload,
  };
};
