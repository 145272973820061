import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "../src/Redux/reducers/index_reducer";

// const PrivateRoutes = React.lazy(() => import("./App"));
const history = createBrowserHistory();

const mystore = store

ReactDOM.render(
  <Provider store={mystore}>
    <Suspense>
      <App history={history} />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
