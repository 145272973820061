export default class Language {


    static Login = 'Login'
    static Warning = 'Warning'
    static LoginText = 'you enter the worng email & password please enter the right email & password'
    static TryAgain = 'Try Again'
    static HelloFriend = 'Hello, Friend!'
    static enterDetails = 'Enter your details and start journey with us'
    static Signin = 'Sign in'
    static anduseyouraccount = 'and use your account'
    static Waitmoment = 'Wait a moment'
    static FillAllfields = 'Fill all fields'
    static InvalidCredential = 'Invalid Credential'
    static YouSuccessfullLogout = 'You Successfull Logout'
    static Notification = 'Notification'
    static Send = 'Send'
    static Norecordfound = 'No record found'
    static Users = 'Users'
    static Expense = 'Expense'
    static Dashboard = 'Dashboard'
    static Email = 'Email'
    static Name = 'Name'
    static Logout = 'Logout'
    static Title = 'Title'
    static Message = 'Message'
    static Image = 'Image'
    static Created_Date = 'Created Date'
    static UserDetail = 'User Detail'
    static Phone = 'Phone'
    static Close = 'Close'
    static SendNotification = 'Send Notification'
    static ChooseImage = 'Choose Image'
    static SelectUser = 'Select User'
    static Submit = 'Submit'
    static Reset = 'Reset'
    static Income = 'Income'
    static User = 'User'
    static Schedule = 'Schedule'
    static Status = 'Status'
    static Notes = 'Notes'
    static Date = 'Date'
    static Action = 'Action'
    static FirstName = 'First Name'
    static LastName = 'Last Name'
    static Country = 'Country'
    static Company = 'Company'
    static Loading = 'Loading'
    static Join_date = 'Join_date'
    static Block_User = 'Block User'
    static Feature = 'Feature'
    static AddNotes = 'Add Notes'
    static Morning = 'Morning'
    static Afternoon = 'Afternoon'
    static Evening = 'Evening'
    static Night = 'Night'
    static Description = 'Description'
    static DescriptionFromAdmin = 'Description From Admin'
    static FailToupdateRecord = 'Fail to update record'
    static Failtosendnotification = 'Fail to send notification'
    static Update = 'Update'
    static EnterEmail = 'Enter Email'
    static EnterPassword = 'Enter Password'
    static Searchnotificationbytitle = 'Search notification by title'
    static Typeyourmessagehere = 'Type your message here'
    static Searchuserbyname = 'Search user by name'

}


