import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Components/Loader";
import API from "../Constants/API";
import Global from "../Constants/Global";
import { addExpense, editExpense } from "../Redux/actions/expense";
import { getAllContactBook } from "../Redux/actions/contactBook";

export default function AddExpense({
    data = null,
    edit = false,
    onEditChange,
}) {
    const dispatch = useDispatch();

    const contactBookLoading = useSelector((state) => state.contactBook.allContactBookLoading);
    const contactBooks = useSelector((state) => state.contactBook.allContactBookData || []);

    const [loading, setLoading] = useState(false);
    const [ContactBookName, setContactBookName] = useState("");
    const [contactBookId, setContactBookId] = useState("");
    const [Received, setReceived] = useState("");
    const [Amount, setAmount] = useState("");
    const [Detail, setDetail] = useState("");
    const [Type, setType] = useState("client");

    useEffect(() => {
        if (edit && data) {
            setContactBookName(data.clientName);
            setReceived(data.received);
            setAmount(data.amount);
            setDetail(data.des);
            setType(data.type);
        } else {
            setContactBookName("");
            setReceived("");
            setAmount("");
            setDetail("");
            setType("");
        }
    }, [data]);

    const handleCallcontactBook = () => {
        dispatch(getAllContactBook(""));
    }

    const OnEditSubmit = async (ev) => {
        ev.preventDefault();
        setLoading(true);
        const closeForm = document.getElementById("expenseButton");

        let DataToSend = {
            id: data.id,
            amount: Amount,
            received: Received,
            clientName: ContactBookName,
            type: Type,
            des: Detail,
            contactBook_id: contactBookId,
        };

        if (Number(Amount) === Number(Received)) {
            DataToSend.paymentStatus = "paid";
        } else {
            DataToSend.paymentStatus = "unpaid";
        }
        await Global.postRequest(API.UPDATE_EXPENSE, DataToSend).then((res) => {
            if (res?.success) {
                dispatch(editExpense({ ...data, ...DataToSend }));
                setContactBookName("");
                setReceived("");
                setAmount("");
                setType("client");
                setDetail("");
                closeForm.click();
                onEditChange();
            }
            setLoading(false);
        });
    };

    const OnSubmit = async (ev) => {
        ev.preventDefault();
        setLoading(true);
        const closeForm = document.getElementById("expenseButton");

        let DataToSend = {
            amount: Amount,
            received: Received,
            clientName: ContactBookName,
            type: Type,
            des: Detail,
            contactBook_id: contactBookId,
        };

        await Global.postRequest(API.ADD_EXPENSE, DataToSend).then((res) => {
            if (res?.success) {
                dispatch(addExpense(res.data));
                setContactBookName("");
                setReceived("");
                setAmount("");
                setDetail("");
                setType("client");
                closeForm.click();
            }
            setLoading(false);
        });
    };

    let btnDisabled = false;
    if (Number(Received) > Number(Amount)) {
        btnDisabled = true;
    }

    let Balance = Amount - Received;

    return (
        <div
            className="modal fade"
            id="expenseModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-dialog-centered modal-dialog-scrollable"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title primary" id="exampleModalCenterTitle">
                            Add New Expense
                        </h5>
                        <button
                            id="expenseButton"
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form
                        className="form form-horizontal"
                        id="sendNotification"
                        onSubmit={(e) => (edit && data ? OnEditSubmit(e) : OnSubmit(e))}
                    >
                        <div className="modal-body">
                            {/* ////////////////////Body */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label">
                                            <label for="Title">Client / ContactBook *</label>
                                        </div>
                                        <div className="col-sm-9">
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    id="client"
                                                    className="form-control form-control-lg"
                                                    name="client"
                                                    value={ContactBookName}
                                                    onChange={(v) => [setContactBookName(v.target.value), setContactBookId(null)]}
                                                    required
                                                />
                                                <div className="input-group-append">
                                                    <button
                                                        onClick={() => handleCallcontactBook()}
                                                        type="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        className="dropdown-toggle btn btn-primary"
                                                    >
                                                        List
                                                    </button>
                                                    <div
                                                        tabindex="-1"
                                                        role="menu"
                                                        aria-hidden="true"
                                                        className="dropdown-menu"
                                                    >
                                                        {contactBookLoading ? <Loader /> : contactBooks.length === 0
                                                            ? null
                                                            : contactBooks.map((e) => (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => [setContactBookName(e.name), setContactBookId(e.id)]}
                                                                    className="dropdown-item"
                                                                >
                                                                    {e.name}
                                                                </button>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label">
                                            <label for="Title">Received *</label>
                                        </div>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                id="received"
                                                className="form-control form-control-lg"
                                                name="received"
                                                value={Received}
                                                onChange={(v) => setReceived(v.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label">
                                            <label for="Title">Amount *</label>
                                        </div>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                id="amount"
                                                className="form-control form-control-lg"
                                                name="amount"
                                                value={Amount}
                                                onChange={(v) => setAmount(v.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label">
                                            <label for="Title">Balance</label>
                                        </div>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                id="balance"
                                                className="form-control form-control-lg"
                                                name="balance"
                                                value={Balance}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="input-group" onClick={() => setType("client")}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <input
                                                        type="radio"
                                                        checked={Type === "client" ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Client"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="input-group" onClick={() => setType("shop")}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <input
                                                        type="radio"
                                                        checked={Type === "shop" ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Shop"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="input-group" onClick={() => setType("salary")}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <input
                                                        type="radio"
                                                        checked={Type === "salary" ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Salary"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="input-group" onClick={() => setType("other")}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <input
                                                        type="radio"
                                                        checked={Type === "other" ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Other"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-12">
                                    <div className="form-group">
                                        <label for="DescriptionFromAdmin">Detail</label>
                                        <input
                                            type="text"
                                            id="DescriptionFromAdmin"
                                            className="form-control"
                                            value={Detail}
                                            onChange={(v) => setDetail(v.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <Alertbar status={alertStatus} message={alertMessage} /> */}
                            {/* ////////////////////Body */}
                        </div>
                        <div className="modal-footer">
                            {loading ? (
                                <div className="spinner-border text-primary" role="status" />
                            ) : (
                                <div>
                                    <button
                                        disabled={btnDisabled ? true : false}
                                        type="submit"
                                        className="btn btn-primary mr-1"
                                    >
                                        Submit
                                    </button>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
