import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import API from "../Constants/API";
import Global from "../Constants/Global";

import { listContactBook } from "../Redux/actions/contactBook";
import { updateDashboard } from "../Redux/actions/auth";

import AddExpense from "../Components/AddExpense";
import AddIncome from "../Components/AddIncome";
import AddContactBook from "../Components/AddContactBook";

export default function Dashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const data = useSelector((state) => state.auth.dashboardData);
    const loading = useSelector((state) => state.auth.dashboardLoading);

    useEffect(() => {
        getDashboardData()
    }, [])


    const getDashboardData = async () => {
        await Global.getRequest(API.GET_DASHBOARD)
            .then(res => {
                if (res?.success) {
                    dispatch(updateDashboard(res.data))
                    if (res?.data?.ContactBookData) dispatch(listContactBook(res.data.ContactBookData))
                }
            })
    }

    return (
        <div class="app-content content">
            <div class="content-overlay"></div>
            <div class="content-wrapper mt-1">

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-left mb-0">
                                    Dashboard
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="content-body">
                    <section id="dashboard-analytics">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="card border-success">
                                    {loading ? <div
                                        className="spinner-border text-success m-5"
                                        role="status"
                                    /> : <div class="card-header d-flex flex-column align-items-start pb-0">
                                        <div class="avatar bg-success p-50 m-0">
                                            <div class="avatar-content">
                                                <a
                                                    type="button"
                                                    className="btn"
                                                    data-toggle="modal"
                                                    data-target="#incomeModal"
                                                >
                                                    <i class="feather icon-plus text-white font-medium-5"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <h2 class="text-bold-700 mt-1 mb-25 text-success">{data.Sell}</h2>
                                        <p class="mb-0">Total Sell</p>
                                    </div>}
                                    <div class="card-body ml-2 mt-1 p-0">
                                        <div class="mb-2 mr-2 badge badge-success">Total Income: {data.Income}</div><div class="mb-2 mr-2 badge badge-light">Cash in Hand: {data.CashInHand}</div>
                                    </div>
                                    <div class="card-footer">
                                        <a onClick={(e) => [e.preventDefault(), navigate("/Income")]} href="Income" class="btn btn-link">
                                            All Income <i class="feather icon-chevrons-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="card border-warning">
                                    {loading ? <div
                                        className="spinner-border text-warning m-5"
                                        role="status"
                                    /> : <div class="card-header d-flex flex-column align-items-start pb-0 text-left">
                                        <div class="avatar bg-warning p-50 m-0 text-left">
                                            <div class="avatar-content">
                                                <a
                                                    type="button"
                                                    className="btn"
                                                    data-toggle="modal"
                                                    data-target="#expenseModal"
                                                >
                                                    <i class="feather icon-minus text-white font-medium-5"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <h2 class="text-bold-700 mt-1 mb-25 text-warning">{data.Expenses}</h2>
                                        <p class="mb-0">Total Expense</p>
                                    </div>}
                                    <div class="card-body ml-2 mt-1 p-0">
                                        <div class="mb-2 mr-2 badge badge-light">Unpaid: {data.Unpaid}</div>
                                    </div>
                                    <div class="card-footer">
                                        <a onClick={(e) => [e.preventDefault(), navigate("/Expense")]} href="Expense" class="btn btn-link">
                                            All Expense <i class="feather icon-chevrons-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="card border-danger">
                                    {loading ? <div
                                        className="spinner-border text-danger m-5"
                                        role="status"
                                    /> : <div class="card-header d-flex flex-column align-items-start pb-0 text-left">
                                        <div class="avatar bg-danger p-50 m-0 text-left">
                                            <div class="avatar-content">
                                                <i class="feather icon-clock text-white font-medium-5"></i>
                                            </div>
                                        </div>
                                        <h2 class="text-bold-700 mt-1 mb-25 text-danger">{data.Pending}</h2>
                                        <p class="mb-0">Pending Payment</p>
                                    </div>}
                                    <div class="card-body ml-2 mt-1 p-0">
                                        <br /><br />
                                        <br /><br />
                                        <br /><br />
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="card border-info">
                                    {loading ? <div
                                        className="spinner-border text-info m-5"
                                        role="status"
                                    /> : <div class="card-header d-flex flex-column align-items-start pb-0 text-left">
                                        <div class="avatar bg-info p-50 m-0 text-left">
                                            <div class="avatar-content">
                                                <a
                                                    type="button"
                                                    className="btn"
                                                    data-toggle="modal"
                                                    data-target="#contactBookModal"
                                                >
                                                    <i class="feather icon-users text-white font-medium-5"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <h2 class="text-bold-700 mt-1 mb-25 text-info">{data?.ContactBook}</h2>
                                        <p class="mb-0">Total Contact Book</p>
                                    </div>}
                                    <div class="card-body ml-2 mt-1 p-0">
                                        <div class="mb-2 mr-2 badge badge-light">Unpaid: {String(data?.Unpaid)}</div>
                                    </div>
                                    <div class="card-footer">
                                        <a onClick={(e) => [e.preventDefault(), navigate("/ContactBook")]} href="ContactBook" class="btn btn-link">
                                            All Contact Book <i class="feather icon-chevrons-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>


                    {/* <!-- Modal for Income --> */}
                    <AddIncome />
                    {/* <!-- Modal End for Income --> */}

                    {/* <!-- Modal for Expense --> */}
                    <AddExpense />
                    {/* <!-- Modal End for Expense --> */}

                    {/* <!-- Modal for ContactBook --> */}
                    <AddContactBook />
                    {/* <!-- Modal End for ContactBook --> */}

                </div>
            </div>
        </div>
    );
}